import logo from './logo.svg';
import './App.css';
import Home from './routes/home';

function App() {
  return (
    <div className="App">
       <Home/>
    </div>
  );
}

export default App;
